import React, { useEffect, useState, useRef } from 'react';
import logo from './logo.png';
import { useForm } from 'react-hook-form';

import './App.css';
import axiosHelper from './helpers/axiosHelper';
import { ProgressBar } from 'react-bootstrap';

function App() {
  const closeDuration = 15;
  const openDuration = 18;
  const direction = useRef(0);
  const interval = useRef(0);
  const [username, setUsername] = useState<string | null>(null);
  const [waitMode, setWaitMode] = useState(false);
  const [now, setNow] = useState(0);
  // const [direction, setDirection] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { register, handleSubmit } = useForm();
  const [last, setLast] = useState<any[]>([]);
  const longDateAndTimeString = (dtstr: string | undefined) => {
    if (!dtstr) {
      return '';
    }
    var date = new Date(Date.parse(dtstr));
    return longDateAndTimeStringFromDate(date);
  };
  const longDateAndTimeStringFromDate = (date: Date) => {
    return (
      date.toLocaleString(undefined, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      }) +
      ' ' +
      date.toLocaleString(undefined, {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
      })
    );
  };

  useEffect(() => {
    axiosHelper
      .me()
      .then((result) => {
        setUsername(result);
      })
      .catch((error) => {
        setUsername(null);
      });
  }, []);

  const checkIfOpen = () => {
    axiosHelper.getStatus().then((value) => {
      setIsOpen(value.isOpen);
    });
    axiosHelper.getLast().then((value) => {
      setLast(value);
    });
  };

  useEffect(() => {
    if (username) {
      checkIfOpen();
    }
  }, [username]);

  useEffect(() => {
    if (waitMode) {
      console.log('waitmode' + now);
      if (now >= 0 && now <= 100) {
        // if (isOpen) {
        setTimeout(() => setNow((oldNow) => oldNow + direction.current), interval.current);
        // } else {
        //   setTimeout(() => setNow((oldNow) => now + 1), 100);
        // }
      } else {
        setWaitMode(false);
      }
    }
  }, [now, waitMode]);

  useEffect(() => {
    if (!waitMode) {
      checkIfOpen();
    }
  }, [waitMode]);

  const startCloseAnimation = () => {
    direction.current = 3;
    interval.current = closeDuration * 30;
    setNow(0);
    setWaitMode(true);
  };

  const startOpenAnimation = () => {
    direction.current = -3;
    interval.current = openDuration * 30;
    setNow(100);
    setWaitMode(true);
  };

  const onSubmit = async (data: any) => {
    try {
      const result = await axiosHelper.login(data.username, data.password);
      setUsername(result);
    } catch (error) {
      console.log(error);
    }
  };

  const buttonClicked = () => {
    if (isOpen) {
      axiosHelper
        .close()
        .then((result) => {
          if (result == null) {
            setUsername(null);
          }
          console.log(result);
        })
        .catch((error) => {
          setUsername(null);
        });
      startCloseAnimation();
    } else {
      axiosHelper
        .open()
        .then((result) => {
          if (result == null) {
            setUsername(null);
          }
          console.log(result);
        })
        .catch((error) => {
          setUsername(null);
        });
      startOpenAnimation();
    }
  };

  return (
    <div className='App'>
      <img src={logo} alt='altera' className='logo' />
      {username ? (
        <>
          {waitMode ? (
            <>
              <div className='progressBar'>
                <ProgressBar striped variant='info' now={now} animated />
              </div>
              {isOpen ? 'Chiusura' : 'Apertura'} in corso
            </>
          ) : (
            <>
              benvenuto {username}
              <br />
              <br />
              <button className='openBtn' onClick={buttonClicked}>
                {isOpen ? <>CHIUDI</> : <>APRI</>}
              </button>
              <br />
              <br />
            </>
          )}
        </>
      ) : (
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <input id='username' placeholder='username' {...register('username', { required: true })} />
              </div>
              <div className='col-12 col-md-6'>
                <div className='pass-wrapper'>
                  <input type='password' id='password' placeholder='password' {...register('password', { required: true })} />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-3 d-flex justify-content-start align-items-center'>
                <button className='loginBtn' type='submit'>
                  Accedi
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      {last ? (
        <div className='last'>
          {last.map((row) => (
            <p key={row.date}>
              {longDateAndTimeString(row.date)} {row.username} {row.action === 'C' ? 'Chiusura' : 'Apertura'}
            </p>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default App;
