import axios from 'axios';
import Constants from './constants';

class AxiosHelper {
    isLoggedIn = false;
    hasToken = false;

    constructor() {
        let token = localStorage.getItem("token");
        if (token !== null) {
            this.setToken(token, true);
            // this.retrieveUserProfile();
            // this.isLoggedIn = true;

        }
    }



    me() {
        return axios.get(`${Constants.API_ENDPOINT}/me`).then((response) => {
            return (response.data);
        })
            .catch((error) => {
                console.log('error');
            })
    }
    getStatus() {
        return axios.get(`${Constants.API_ENDPOINT}/status`).then((response) => {
            return (response.data);
        })
            .catch((error) => {
                console.log('error');
            })
    }
    getLast() {
        return axios.get(`${Constants.API_ENDPOINT}/last`).then((response) => {
            return (response.data);
        })
            .catch((error) => {
                console.log('error');
            })
    }
    open() {
        return axios.get(`${Constants.API_ENDPOINT}/open`).then((response) => {
            return (response.data);
        })
            .catch((error) => {
                console.log('error');
            })
    }
    close() {
        return axios.get(`${Constants.API_ENDPOINT}/close`).then((response) => {
            return (response.data);
        })
            .catch((error) => {
                console.log('error');
            })
    }

    login(username: string, password: string) {
        localStorage.removeItem("token");
        return axios
            .post(`${Constants.API_ENDPOINT}/login`, { username: username, password: password })
            .then((response) => {
                console.log(response.data);
                this.setToken(response.data.token, true);
                return response.data.user;
            })
            .catch((error) => {
                return null;
            });
    }


    setToken(token: string, permanently: boolean) {
        this.hasToken = true;
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        if (permanently) {
            localStorage.setItem("token", token);
        }
    }
}

export default new AxiosHelper();
